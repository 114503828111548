@font-face {
    font-family: Roboto-Regular;
    src: url("./assets/fonts/Roboto/Roboto-Black.ttf");
}
@font-face {
    font-family: Roboto-Regular;
    src: url("./assets/fonts/Roboto/Roboto-Bold.ttf");
}
@font-face {
    font-family: Roboto-Medium;
    src: url("./assets/fonts/Roboto/Roboto-Light.ttf");
}
@font-face {
    font-family: Roboto-Bold;
    src: url("./assets/fonts/Roboto/Roboto-Medium.ttf");
}
@font-face {
    font-family: Roboto-Bold;
    src: url("./assets/fonts/Roboto/Roboto-Regular.ttf");
}
@font-face {
    font-family: Roboto-Bold;
    src: url("./assets/fonts/Roboto/Roboto-Thin.ttf");
}